import React from "react";
import MuiGrid, { GridProps } from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => {
	return {
		container: {
			padding: 30,
			[theme.breakpoints.down(theme.breakpoints.values.xs)]: {
				padding: "24px 12px",
			},
		},
		print: {
			"@media print": {
				breakInside: "avoid",
			},
		},
	};
});

export const Grid = ({ children, className, ...rest }: GridProps) => {
	const styles = useStyles();
	return (
		<MuiGrid
			container
			spacing={2}
			className={clsx(styles.container, className)}
		>
			{children}
		</MuiGrid>
	);
};

export const GridItem = ({ children, ...rest }: GridProps) => {
	const styles = useStyles();
	return (
		<MuiGrid item xs={12} sm={6} {...rest} className={styles.print}>
			{children}
		</MuiGrid>
	);
};
