import { makeStyles } from "@material-ui/core";

export const customStyles = makeStyles(() => ({
	printTitle: {
		display: "flex",
		flexDirection: "column",
		margin: "24px 0",
		padding: "24px 48px",
	},
}));
