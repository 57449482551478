import { createPrivateView, ViewComponent } from "~/model/view/private";

import type { LeadViewProps } from "./types";

export type LeadViewComponent = ViewComponent<LeadViewProps>;

export const createLeadView: typeof createPrivateView = (
	component,
	props = {},
) => {
	return createPrivateView(component, {
		...props,
		showBack: true,
		backTo: "/leads/",
		layoutProps: {},
	});
};

export type { ViewComponent };
