import React from "react";
import dayjs from "dayjs";

import Box from "@material-ui/core/Box";

import { Typography } from "~/components/Typography";

import { customStyles } from "./styles";
import { useLeadState } from "@leads/context";

const LeadPrintHeader = () => {
	const styles = customStyles();
	const {
		state: { lead },
	} = useLeadState();
	const fullName = [lead?.firstName, lead?.lastName]
		.filter(Boolean)
		.join(" ");
	const date = dayjs(lead?.createdAt).format("DD MMM YYYY");

	return (
		<Box className={styles.printTitle}>
			<Box alignSelf="center">
				<Typography variant="title">{fullName}</Typography>
				<Typography variant="body1" align="center">
					{dayjs(date).format("DD MMM YYYY")}
				</Typography>
			</Box>
			<Box alignSelf="center">
				{"This information will expire 30 days from the 'Date Applied'"}
			</Box>
		</Box>
	);
};

export default LeadPrintHeader;
