import React, { LegacyRef, useEffect, useRef } from "react";

import { createLeadView, LeadViewComponent } from "../_lib/view";

import { LeadStateProvider, useLeadState } from "../_lib/context";

import { PersonalInformation } from "@leads/cards/PersonalInformation";
import { ContactInformation } from "@leads/cards/ContactInformation";
import { LeadInformation } from "@leads/cards/LeadInformation";
import { CreditScore } from "@leads/cards/CreditScore/Card";
import { HomeInformation } from "@leads/cards/HomeInformation";
import { EmploymentInformation } from "@leads/cards/EmploymentInformation";
import { IdVerification } from "@leads/cards/IdVerification/Card";
import { MarketingInformation } from "@leads/cards/MarketingInformation";
import { Grid, GridItem } from "@leads/Grid";
import { TradeVehicles } from "@leads/cards/TradeVehicle";
import LeadPrintHeader from "@leads/Print/PrintHeader";

const BasicView: LeadViewComponent = (props) => {
	const {
		state,
		refs,
		actions: { handlePrint },
	} = useLeadState();
	useEffect(() => {
		// PRINT IF STATE IS TRUE
		if (!state.loading && !state.idLoading && !state.printed) {
			setTimeout(handlePrint, 500);
		}
	}, [state.loading, state.idLoading, state.printed, handlePrint]);

	return (
		<>
			<GridView ref={refs.print} />
		</>
	);
};

// eslint-disable-next-line react/display-name
export const GridView = React.forwardRef((_, ref) => {
	return (
		<div ref={ref as LegacyRef<HTMLDivElement>}>
			<LeadPrintHeader />
			<Grid>
				<GridItem>
					<PersonalInformation />
				</GridItem>
				<GridItem>
					<CreditScore />
				</GridItem>
				<GridItem>
					<ContactInformation />
				</GridItem>
				<GridItem>
					<LeadInformation />
				</GridItem>

				<GridItem>
					<HomeInformation />
				</GridItem>
				<GridItem>
					<EmploymentInformation />
				</GridItem>

				<GridItem sm={12}>
					<IdVerification />
				</GridItem>

				<TradeVehicles />

				<GridItem sm={12}>
					<MarketingInformation />
				</GridItem>
			</Grid>
		</div>
	);
});

const LeadView: LeadViewComponent = (props) => {
	const layoutProps = {
		...props,
		pageProps: props.params,
		title: "Lead Details",
		showBack: true,
		backTo: "/leads/",
		children: undefined,
	};

	return (
		<LeadStateProvider {...layoutProps}>
			<BasicView {...layoutProps} />
		</LeadStateProvider>
	);
};

export default createLeadView(LeadView, {});
