import React, {
	useContext,
	createContext,
	useRef,
	MutableRefObject,
	useState,
} from "react";

import { useLeadView } from "@api/leads";

import { useMemo, useCallback } from "react";
import { IComponentNavigateFn } from "@utils/navigate";
import { LeadDetails } from "~/model/leads";
import { ProductType } from "~/model/products";
import { ReactNode } from "react";
import { WindowLocation } from "@reach/router";
import { useReactToPrint } from "react-to-print";
import { IRetrieveIdActions, useRetrieveIdVerification } from "@api/id";
import { RetrieveIdVerificationMutation } from "@api/graphql/types";

const useShowHide = (navigate: IComponentNavigateFn, key: string) => {
	const open = useCallback(() => {
		navigate(
			{
				query: { [key]: true },
			},
			{
				keepQuery: true,
				saveState: true,
				replace: true,
				state: {
					skipRouteChangeEvent: true,
				},
			},
		);
	}, [key, navigate]);

	const close = useCallback(() => {
		navigate("?", {
			removeQuery: [key],
			keepQuery: true,
			saveState: true,
			replace: true,
			state: {
				skipRouteChangeEvent: true,
			},
		});
	}, [key, navigate]);

	return [open, close];
};

const useLeadActions = (navigate: IComponentNavigateFn) => {
	const [openComments, closeComments] = useShowHide(navigate, "showComments");
	const [openDelete, closeDelete] = useShowHide(navigate, "showDelete");

	const getProducts = useCallback((lead: LeadDetails) => {
		const products: ProductType[] = [];
		if (lead?.avaApp) {
			products.push(ProductType.CreditTool);
		}
		if (lead?.tradeApps && lead.tradeApps.nodes.length > 0) {
			products.push(ProductType.TradeTool);
		}
		return products;
	}, []);

	return useMemo(
		() => ({
			openComments,
			closeComments,
			openDelete,
			closeDelete,
			getProducts,
		}),
		[openComments, closeComments, openDelete, closeDelete, getProducts],
	);
};

type LeadActions = {
	openComments: () => void;
	closeComments: () => void;
	openDelete: () => void;
	closeDelete: () => void;
	getProducts: (lead: LeadDetails) => ProductType[];
};

type LeadState = {
	actions: LeadActions &
		ReturnType<typeof useLeadView>["actions"] &
		IRetrieveIdActions & {
			triggerPrint: () => void;
			handlePrint: () => void;
		};
	state: Omit<ReturnType<typeof useLeadView>, "actions"> & {
		print: boolean;
		printed: boolean;
		idVerified: NonNullable<
			RetrieveIdVerificationMutation["retrieveIdVerification"]
		>["data"];
		idLoading: boolean;
	};
	refs: {
		print: MutableRefObject<any>;
	};
};

const LeadStateContext = createContext({} as LeadState);

export const useLeadState = () => {
	const leadContext = useContext(LeadStateContext);

	if (!leadContext) {
		throw new Error(
			[
				"You can't use `leadContext` from outside",
				"of a `<LeadStateContext.Provider>`",
			].join(" "),
		);
	}

	return leadContext;
};

interface PrintProps {
	leadId: string;
	navigate: IComponentNavigateFn;
}

export const usePrint = ({ leadId, navigate }: PrintProps) => {
	const printRef = useRef(null);
	const [print, setPrint] = useState(false);
	const [printed, setPrinted] = useState(false);

	const hook = useReactToPrint({
		content: () => printRef.current,
		documentTitle: `Lead Details - ${new Date().toString()}`, //`${fullName} - ${leadCreatedDay}`,
		onAfterPrint: () => {
			navigate(-1, {
				backPathFallback: `/leads/${leadId}/`,
				back: true,
			});
		},
	});

	const handlePrint = () => {
		setPrint(true);
		setPrinted(true);
		hook();
	};

	const triggerPrint = () => {
		navigate(`/leads/${leadId}/print`, { saveState: true });
	};

	return {
		refs: {
			print: printRef,
		},
		actions: {
			handlePrint,
			triggerPrint,
		},
		state: {
			print,
			printed,
		},
	};
};

interface ProviderProps {
	leadId: string;
	location: WindowLocation<any>;
	navigate: IComponentNavigateFn;
	children: ReactNode;
	params: Record<string, string>;
}

export const LeadStateProvider = ({
	location,
	leadId,
	children,
	navigate,
}: ProviderProps) => {
	const { actions, ...rest } = useLeadView(
		leadId,
		location.state?.cognitoName ?? "",
	);

	const leadActions = useLeadActions(navigate);

	const print = usePrint({ leadId, navigate });
	const idVerification = useRetrieveIdVerification();

	return (
		<LeadStateContext.Provider
			value={{
				state: {
					...rest,
					...print.state,
					...idVerification.state,
					loading: rest.loading,
					idLoading: idVerification.state.loading,
				},
				actions: {
					...actions,
					...leadActions,
					...print.actions,
					...idVerification.actions,
				},
				refs: { ...print.refs },
			}}
		>
			{children}
		</LeadStateContext.Provider>
	);
};
